export function isLoggedIn() {
  if (typeof document === `undefined`) {
    return false;
  }
  return document.cookie.indexOf("loggedIn") !== -1;
}

export function SortSounds(sounds) {
  sounds.sort(function (a, b) {
    var nameA = a.name.toUpperCase(); // ignore upper and lowercase
    var nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  });
  return sounds;
}

export function alertErrorHelper(prefix, errorResp) {
  errorResp.text().then((text) => {
    try {
      const json = JSON.parse(text);
      const errormessage = json.errors[0];
      window.alert(`${prefix} failed: ${errormessage}`);
    } catch (err) {
      window.alert(`Unexpected error: ${errorResp.status} ${text}`);
    }
  });
}

export function redirectOn401(resp, f) {
  if (resp.status === 401) {
    window.alert(
      "You've been logged out. Click okay to be redirected to the home page."
    );

    window.location = "/logout";
  }

  return f(resp);
}

const utils = { isLoggedIn, alertErrorHelper, redirectOn401 };

export default utils;
