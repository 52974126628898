import React, { Component } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Container,
  Avatar,
  Button,
  Tooltip,
  Popover,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import * as NavBarStyles from "./styles/NavBar.module.css";

import { Link } from "@reach/router";

import discordLogo from "./images/discord-icon.png";
import NavDrawerMenu from "./NavDrawerMenu";
import { isLoggedIn } from "./server-state/util";
import { CurrentUserContext } from "./server-state/CurrentUserContext";
export default class NavBar extends Component {
  // This component doesn't use any properties

  constructor(props, context) {
    super(props, context);

    this.state = {
      UserMenuAnchor: null,
      NavDrawerLeft_isOpen: false,
      UserOptions_isOpen: false,
    };
  }

  clearSavedLogin = () => {
    window.location.replace("/logout");
  };

  onClick_Discord_LogInButton = (ev) => {
    window.location = "/login";
  };

  onClick_DiscordLink = (ev) => {
    window.location = "https://discord.gg/XASDPeB";
  };

  onClick_NavDrawerLeftButton = (ev) => {
    this.toggle_NavDrawerLeft();
  };

  toggle_NavDrawerLeft = () => {
    this.setState({ NavDrawerLeft_isOpen: !this.state.NavDrawerLeft_isOpen });
  };

  handleUserMenuOpen = (event) => {
    this.setState({ UserMenuAnchor: event.currentTarget });
  };

  handleUserMenuClose = () => {
    this.setState({ UserMenuAnchor: null });
  };

  render() {
    const loggedIn = isLoggedIn();
    const generalLinks = [
      {
        name: "Invite!",
        link: "https://discord.com/api/oauth2/authorize?client_id=699618119789969509&permissions=103902272&scope=bot",
      },
      { name: "Donate", link: "https://ko-fi.com/P5P54RD8P" },
      { name: "Docs", link: "/docs/" },
      { name: "Dev", link: "https://discord.gg/XASDPeB" },
    ];
    const loggedInLinks = [
      { name: "Sounds", link: "/app/sounds", class: "soundsLink" },
      {
        name: "Sound Profiles",
        link: "/app/soundprofileeditor",
        class: "soundProfiles",
      },
    ];
    const { UserMenuAnchor } = this.state;
    const userMenuIsOpen = Boolean(UserMenuAnchor);
    const userOptionsPopOver = (
      <div>
        <Tooltip title="User Settings">
          <IconButton sx={{ p: 0 }} onClick={this.handleUserMenuOpen}>
            <Avatar alt={this.context.user.discord_username} src="" />
          </IconButton>
        </Tooltip>
        <Popover
          open={userMenuIsOpen}
          anchorEl={UserMenuAnchor}
          onClose={this.handleUserMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Typography sx={{ p: 2 }}>
            <span class={NavBarStyles.UserMenuHeader}>
              {this.context.user.discord_username}
            </span>
            <Button
              className={`${NavBarStyles.NavBarLogoutButton}`}
              onClick={this.clearSavedLogin}
            >
              <Link onClick={this.props.closeDrawer} to="/"></Link>
              Log Out
            </Button>
          </Typography>
        </Popover>
      </div>
    );

    return (
      <div>
        <AppBar sx={{ background: "black" }} className="NavBar">
          <Container maxWidth="false">
            <Toolbar disableGutters>
              {/* ---------------------------------------------------------------------- */}
              <Box sx={{ flexGrow: 0, ml: 0 }}>
                <IconButton
                  className="drawerButton"
                  size="large"
                  aria-label="Drawer menu"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={this.onClick_NavDrawerLeftButton}
                  sx={{ flexGrow: 0, ml: 0 }}
                >
                  <MenuIcon sx={{ fontSize: 40, color: "rgb(25, 118, 210)" }} />
                </IconButton>
              </Box>
              {/* ---------------------------------------------------------------------- */}
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
              >
                <Link to="/">
                  <Button className={NavBarStyles.discordDungeonLogo}></Button>
                </Link>
              </Typography>
              {/* ---------------------------------------------------------------------- */}
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
              >
                <Link to="/">
                  <Button className={NavBarStyles.discordDungeonLogo}></Button>
                </Link>
              </Typography>
              {/* ---------------------------------------------------------------------- */}
              <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                {loggedIn &&
                  loggedInLinks.map((loggedInLink) => (
                    <Button
                      id={`${loggedInLink.class}`}
                      className={`${NavBarStyles.NavBarNavButtons}`}
                      key={loggedInLink.name}
                      href={loggedInLink.link}
                      size="large"
                      sx={{ my: 2, display: "block" }}
                    >
                      {loggedInLink.name}
                    </Button>
                  ))}
                {generalLinks.map((link) => (
                  <Button
                    className={`${NavBarStyles.NavBarNavButtons}`}
                    key={link.name}
                    href={link.link}
                    size="large"
                    sx={{ my: 2, display: "block" }}
                  >
                    {link.name}
                  </Button>
                ))}
              </Box>
              {/* ---------------------------------------------------------------------- */}
              <Box sx={{ flexGrow: 0 }}>
                {loggedIn && userOptionsPopOver}
                {!loggedIn && (
                  <Button
                    className={`${NavBarStyles.NavBarLoginButton}`}
                    onClick={this.onClick_Discord_LogInButton}
                  >
                    Login
                    <img
                      class={NavBarStyles.LoginDiscordIcon}
                      src={discordLogo}
                      alt=""
                      style={{ paddingLeft: "7px" }}
                    ></img>
                  </Button>
                )}
              </Box>
              {/* ---------------------------------------------------------------------- */}
            </Toolbar>
          </Container>
        </AppBar>
        <NavDrawerMenu
          NavDrawerLeft_isOpen={this.state.NavDrawerLeft_isOpen}
          closeDrawer={this.toggle_NavDrawerLeft}
        />
      </div>
    );
  }
}

NavBar.contextType = CurrentUserContext;
