import React from "react";
import { isLoggedIn, alertErrorHelper, redirectOn401 } from "./util";

export const CurrentUserContext = React.createContext({
  user: {},
  updateCurrentUser: () => {},
});

export class CurrentUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = { currentUser: {} };
  }

  url = "/api/v1/me";
  loadCurrentUser = () => {
    fetch(this.url)
      .then((resp) =>
        redirectOn401(resp, (response) => {
          if (response.status >= 400) {
            alertErrorHelper(`Loading user`, response);
            throw new Error("Server Error: " + response.status);
          }
          return response.json();
        })
      )
      .then((user) => {
        this.setState({ currentUser: user });
        localStorage.setItem("userInfo", `${JSON.stringify(user)}`);
      });
  };

  updateCurrentUser = (newUser) => {
    const opts = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newUser),
    };
    fetch(this.url, opts)
      .then((resp) =>
        redirectOn401(resp, (resp) => {
          if (resp.status >= 400) {
            alertErrorHelper(`Updating Current user`, resp);
            throw new Error("Server Error: " + resp.status);
          }
          return resp.json();
        })
      )
      .then((user) => {
        this.setState({ currentUser: user });
        localStorage.setItem("userInfo", `${JSON.stringify(user)}`);
      });
  };

  componentDidMount = () => {
    if (!isLoggedIn()) {
      return;
    }
    this.loadCurrentUser();
  };

  render() {
    return (
      <CurrentUserContext.Provider
        value={{
          user: this.state.currentUser,
          updateCurrentUser: this.updateCurrentUser,
        }}
      >
        {this.props.children}
      </CurrentUserContext.Provider>
    );
  }
}
