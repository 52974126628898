import React, { Component } from "react";
import {
  Drawer,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
  Link,
} from "@mui/material";
import { isLoggedIn } from "./server-state/util";

export interface Props {
  NavDrawerLeft_isOpen: boolean;
  closeDrawer: () => void;
}

export interface State {}

class NavDrawerMenu extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate() {}

  render() {
    const loggedIn = isLoggedIn();
    const GlobalLinks = [
      {
        name: "Invite!",
        link: "https://discord.com/api/oauth2/authorize?client_id=699618119789969509&permissions=103902272&scope=bot",
      },
      { name: "Donate!", link: "https://ko-fi.com/P5P54RD8P" },
      { name: "Documentation", link: "/docs/" },
      { name: "Dev Discord Server", link: "https://discord.gg/XASDPeB" },
      { name: "Games", link: "/app/games" },
      { name: "Extras", link: "/extras/" },
    ];

    const LogedInLinks = [
      { name: "Sounds", link: "/app/sounds", class: "sounds" },
      {
        name: "Sound Profiles",
        link: "/app/soundprofileeditor",
        class: "soundProfiles",
      },
    ];

    return (
      <Drawer
        anchor="left"
        PaperProps={{
          sx: {
            backgroundColor: "#343434",
            color: "white",
            minWidth: "150px",
          },
        }}
        open={this.props.NavDrawerLeft_isOpen}
        onClose={this.props.closeDrawer}
      >
        <Box role="presentation">
          {loggedIn && (
            <List>
              {LogedInLinks.map((LoggedInLink) => (
                <ListItem
                  className={LoggedInLink.class}
                  button
                  component={Link}
                  key={LoggedInLink.name}
                  disablePadding
                  href={LoggedInLink.link}
                >
                  <ListItemButton>
                    <ListItemText
                      primary={LoggedInLink.name}
                      primaryTypographyProps={{
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          )}
          <Divider />
          <List>
            {GlobalLinks.map((GlobalLink) => (
              <ListItem
                button
                component={Link}
                key={GlobalLink.name}
                disablePadding
                href={GlobalLink.link}
              >
                <ListItemButton href={GlobalLink.link}>
                  <ListItemText
                    primary={GlobalLink.name}
                    primaryTypographyProps={{
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    );
  }
}

export default NavDrawerMenu;
